  
.header {
    position: relative;
    z-index: 1;
  
    

    nav {
        position: relative;
        padding-top: 1.0625rem;
        padding-bottom: 1.0625rem;
    }

    &__logo{

        img{
            height: 1rem;
        }

    }

    &__toggle{ // Mobile Menu Toggle

        > span {
            display: block;
            width: 28px;
            height: 2px;
            background-color: darkblue;
            transition: all 300ms ease-in-out;
            transform-origin: 4px 1px;
            
            &:not(:last-child) {
                margin-bottom: 5px;
            }
        }


    }

    &__menu {
        position: absolute;
        width: calc(100% - 5rem);
        left: 50%;
        transform: translateX(-50%);
        background: white;
        margin-top: 1.5rem;
        padding: 1.625rem;
        border-radius: 5px;
        

        a {
            color: blue;  
            padding: 0.625rem;
            display: block;
            text-align: center;
        }
    }

    &__links{
        a {
            position: relative;
            font-size: 1rem;

            &:not(:last-child){
                margin-right: 32px;
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                height: 6px;
                left: 0;
                right: 0;
                bottom: -30px;
                background: #0A81D1;
                opacity: 0;
                transition: opacity 300ms ease-in-out;
                border-radius: 10px;
            }

            &:hover {
                color: white;
                transition: color 300ms ease-in-out;
                &::before{
                    opacity: 1;
                }
            }
        }
    }

    &__cta {
        font-size:  0.875rem;
        color: white;
        transition: opacity 200ms ease-in-out;

        &:hover {
            opacity: 0.75;
        }
    }

    // border: black 2px solid;
}


// Logo Separate Class
.logo-header {
    height: auto;
    width: 4rem;
    margin-right: 5.5rem;
    margin-left: 2rem;


}

