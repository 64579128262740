@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400&display=swap');
@import "./variables";
@import "./mixins";



html{
    font-size: 100%;
    box-sizing: border-box;
    height: 100%;
    
    
}

*, *::before, *::after {
    box-sizing: inherit;
}

body{
    margin: 0;
    padding: 0;
    font-family: 'Public Sans', sans-serif;
    line-height: 1.3;
    font-size: 1.125rem;
    color: lightcoral;
    height: 100%;

    &.noscroll {
        overflow: hidden;
      }
}


// Handles Links
a, a:visited, a:hover {
    text-decoration: none;
    color: white;
}

// Icons
.icon-a{
    margin-right: 6px;
}

// Flexbox
.flex {
    display: flex;

    &-jc-sb {
        justify-content: space-between;
    }

    &-jc-c {
        justify-content: center;
    }

    &-ai-c {
        align-items: center;
    }
}

// Buttons
.nav-button {
    padding: 0.875rem 2.1875rem;
    border-radius: 50px;
    cursor: pointer;
    border: 0;
    color: white;
    font-weight: 450;

    background-image: linear-gradient(147deg, #3F88C5, #FF7F51);

}



// Spacing
.container {
    padding-left:1.5rem;
    padding-right: 1.5rem;
}