.has-fade {
    visibility: hidden;
}
  
@keyframes fade-in {
    from {
        visibility: hidden;
        opacity: 0;
    }
    1% {
        visibility: visible;
        opacity: 0;
    }
    to {
        visibility: visible;
        opacity: 1;
    }
}
.fade-in {
    animation: fade-in 200ms ease-in-out forwards;
}
  
@keyframes fade-out {
    from {
        visibility: visible;
        opacity: 1;
    }
    99% {
        visibility: visible;
        opacity: 0;
    }
    to {
        visibility: hidden;
        opacity: 0;
    }
}

.fade-out {
    animation: fade-out 200ms ease-in-out forwards;
}